import { LockIcon } from '@vivino/js-react-common-ui';
import {
  BUY_PREMIUM_GIFT_SCREEN_NAME,
  BurgerMenuIcon,
  CART_PAGE_SCREEN_NAME,
  CHECKOUT_PAGE_SCREEN_NAME,
  CartsContext,
  CrossIcon,
  DetectBreakpoint,
  Divider,
  Loader,
  LoaderType,
  PARTNERS_CHECKOUT_SCREEN_NAME,
  ShipToType,
  VivinoLogo,
  getShowPremium,
  getTrackingScreen,
  i18nRootUrl,
  isStatePathSupported,
  isVCCountry,
  retry,
  useI18N,
} from '@vivino/js-web-common';
import cx from 'classnames';
import { CartsContextReturnType } from 'frontend/contexts/CartsContext/CartsContext';
import React, { Suspense, lazy, useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import AccessibilityToggleButton from './AccessibilityToggleButton/AccessibilityToggleButton';
import { BURGER_ITEM_ANIMATION_DURATION } from './BurgerMenuItem/BurgerMenuItem.presentational';
import CartMenu, { CheckoutCartMenuLabel } from './CartMenu/CartMenu.container';
import LanguageDropdown from './LanguageDropdown/LanguageDropdown.container';
import SearchBar from './SearchBar/SearchBar.container';
import ShipToDropdown from './ShipToDropdown/ShipToDropdown.container';
import UserMenu from './UserMenu/UserMenu.presentational';
import VerticalSeparator from './VerticalSeparator/VerticalSeparator';
import WineNavigationMenu from './WineNavigationMenu/WineNavigationMenu.presentational';
import styles from './navigation.module.scss';
import transitions from './transitions.scss';

const TRANSLATIONS = {
  secureCheckout: 'checkout.heading.secure_checkout',
};

const isCartPage = getTrackingScreen() === CART_PAGE_SCREEN_NAME;

const getMobileMenuData = (menuData) =>
  menuData.filter(
    (menu) => menu.icon === 'offers' || menu.icon === 'wine' || menu.icon === 'premium'
  );

const Logo = () => (
  <a href={i18nRootUrl({ stateCode: null })} className={styles.logo} aria-label="vivino.com">
    <VivinoLogo />
  </a>
);

const BURGER_MENU_ID = 'burger_menu_id';

const BurgerMenuDrawer = lazy(() =>
  retry(
    () =>
      // @ts-ignore
      import(
        /* webpackChunkName: "BurgerMenuDrawer" */
        './BurgerMenuDrawer/BurgerMenuDrawer.presentational'
      )
  )
);

export interface CellarLink {
  name: string;
  url: string;
}

interface NavigationProps {
  menuData?: any[];
  stateCode?: string;
  countryCode?: string;
  isGhosting?: boolean;
  showDevLine?: boolean;
  trackingScreenName?: string;
  initialUserNotification?: string;
}

const Navigation = ({
  stateCode,
  countryCode,
  isGhosting,
  showDevLine,
  menuData,
  trackingScreenName,
  initialUserNotification,
}: NavigationProps) => {
  const { t } = useI18N();
  const [visibleOuterNavigationId, setVisibleOuterNavigationId] = useState(null);
  const [visibleNavigationId, setVisibleNavigationId] = useState(null);
  const observerRef = useRef(null);
  const { carts } = useContext<CartsContextReturnType>(CartsContext);
  const cart = carts[0];

  const isBuyPremiumGift = trackingScreenName === BUY_PREMIUM_GIFT_SCREEN_NAME;
  const isPartnersCheckout = trackingScreenName === PARTNERS_CHECKOUT_SCREEN_NAME;
  const isNewCheckout = trackingScreenName === CHECKOUT_PAGE_SCREEN_NAME;
  const isCheckout = getTrackingScreen() === CHECKOUT_PAGE_SCREEN_NAME || isNewCheckout;

  useEffect(() => {
    observerRef.current = new IntersectionObserver(handleMenuItemLeavesViewport, {
      rootMargin: '0px',
      threshold: 0,
    });

    return () => {
      observerRef.current.disconnect();
    };
  }, []);

  // If the menu item leaves the viewport due to the animated scrolling
  // we scroll to that element
  function handleMenuItemLeavesViewport(changes) {
    setTimeout(() => {
      changes.forEach((change) => {
        if (change.intersectionRatio === 0) {
          change.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        observerRef.current.unobserve(change.target);
      });
    }, BURGER_ITEM_ANIMATION_DURATION);
  }

  function handleWineMenuChange({ id, ref }) {
    if (ref) {
      observerRef.current.observe(ref);
    }
    setVisibleNavigationId(visibleNavigationId === id ? null : id);
  }

  function handleMenuChange({ id }) {
    setVisibleOuterNavigationId(visibleOuterNavigationId === id ? null : id);
  }

  function renderWineNavigationMenu(isMobile: boolean, menuData?: any[]) {
    if (isCheckout || isNewCheckout || isBuyPremiumGift || isPartnersCheckout) {
      return null;
    }

    return (
      <>
        {!isMobile && <Divider className={styles.divider} />}
        <div className={styles.wineNav}>
          <WineNavigationMenu
            className={styles.wineNavMenu}
            showChevron={false}
            menuData={isMobile ? getMobileMenuData(menuData) : menuData}
            showPremium={getShowPremium()}
            visibleNavigationId={visibleNavigationId}
            onMenuChanged={handleWineMenuChange}
            countryCode={countryCode}
          />
        </div>
      </>
    );
  }

  function renderBurgerMenu(menuData) {
    const show = BURGER_MENU_ID === visibleOuterNavigationId;

    return (
      <CSSTransition in={show} timeout={500} classNames={{ ...transitions }} unmountOnExit>
        <div className={styles.burgerPanel}>
          <Suspense fallback={<Loader type={LoaderType.Linear} alignTop />}>
            <BurgerMenuDrawer
              menuData={menuData}
              visibleNavigationId={visibleNavigationId}
              onMenuChanged={handleWineMenuChange}
              countryCode={countryCode}
              stateCode={stateCode}
              closeOuterMenu={() => handleMenuChange({ id: null })}
            />
          </Suspense>
        </div>
      </CSSTransition>
    );
  }

  function renderMobileNavigation() {
    return (
      <>
        <div className={styles.leftNav}>
          <a
            href="#"
            className={styles.burgerIcon}
            onClick={() => handleMenuChange({ id: BURGER_MENU_ID })}
          >
            {BURGER_MENU_ID === visibleOuterNavigationId ? (
              <CrossIcon className={styles.closeIcon} />
            ) : (
              <BurgerMenuIcon />
            )}
          </a>
          <div className={styles.flexFiller} />
          <Logo />
        </div>
        <div className={styles.rightNav}>
          <AccessibilityToggleButton />
          <VerticalSeparator />
          <UserMenu
            visibleNavigationId={visibleOuterNavigationId}
            onMenuChanged={handleMenuChange}
            initialUserNotification={initialUserNotification}
          />
          {!isCartPage && (
            <SearchBar
              onMenuChanged={handleMenuChange}
              visibleNavigationId={visibleOuterNavigationId}
              isMobile
            />
          )}
          {isVCCountry(countryCode) && (
            <>
              {cart?.id && isCheckout ? (
                <CheckoutCartMenuLabel totalBottles={cart?.total_bottle_count} cartId={cart?.id} />
              ) : (
                <CartMenu
                  visibleNavigationId={visibleOuterNavigationId}
                  onMenuChanged={handleMenuChange}
                  countryCode={countryCode}
                  isGhosting={isGhosting}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }

  function renderDesktopNavigation() {
    return isBuyPremiumGift || (cart && isCheckout) || isPartnersCheckout ? (
      <div className={styles.isCheckout}>
        <div className={styles.leftNav}>
          <Logo />
          <div className={styles.secureCheckout}>
            <LockIcon className={styles.secureCheckoutIcon} />
            {t(TRANSLATIONS.secureCheckout)}
          </div>
        </div>
        <div className={styles.rightNav}>
          <UserMenu
            visibleNavigationId={visibleOuterNavigationId}
            onMenuChanged={handleMenuChange}
            initialUserNotification={initialUserNotification}
          />
          {cart && (
            <div className={styles.checkoutCart}>
              <CheckoutCartMenuLabel totalBottles={cart.total_bottle_count} cartId={cart.id} />
            </div>
          )}
        </div>
      </div>
    ) : (
      <>
        <div className={styles.leftNav}>
          <Logo />
          <SearchBar
            onMenuChanged={handleMenuChange}
            visibleNavigationId={visibleOuterNavigationId}
            isMobile={false}
          />
        </div>
        <div className={styles.rightNav}>
          <ShipToDropdown
            stateCode={stateCode}
            countryCode={countryCode}
            visibleNavigationId={visibleOuterNavigationId}
            onMenuChanged={handleMenuChange}
          />
          {isStatePathSupported(countryCode) && (
            <ShipToDropdown
              stateCode={stateCode}
              countryCode={countryCode}
              visibleNavigationId={visibleOuterNavigationId}
              onMenuChanged={handleMenuChange}
              shipToType={ShipToType.State}
            />
          )}
          <LanguageDropdown
            visibleNavigationId={visibleOuterNavigationId}
            onMenuChanged={handleMenuChange}
          />
          <AccessibilityToggleButton />
          <VerticalSeparator />
          <UserMenu
            visibleNavigationId={visibleOuterNavigationId}
            onMenuChanged={handleMenuChange}
            initialUserNotification={initialUserNotification}
          />
          {isVCCountry(countryCode) && (
            <CartMenu
              visibleNavigationId={visibleOuterNavigationId}
              onMenuChanged={handleMenuChange}
              countryCode={countryCode}
              isGhosting={isGhosting}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <div className={cx(styles.container, { [styles.containerDev]: showDevLine })}>
      <DetectBreakpoint>
        {({ isMobile }) => (
          <>
            <nav
              className={cx(styles.navigation, {
                [styles.isMenuOpen]: visibleNavigationId === BURGER_MENU_ID,
              })}
            >
              {isMobile ? renderMobileNavigation() : renderDesktopNavigation()}
            </nav>
            {isMobile && renderBurgerMenu(menuData)}
            {(!isBuyPremiumGift || !isCartPage || !isPartnersCheckout) &&
              renderWineNavigationMenu(isMobile, menuData)}
          </>
        )}
      </DetectBreakpoint>
    </div>
  );
};

export default Navigation;
